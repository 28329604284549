<template>
    <top-bar>
        <ui-alert v-model="showStripeLink" class="td-alert">
            <div style="float: right;">
                <ui-button outlined icon="public" @click="goToStripe"> {{ $t('purchases.stripeLink') }}
                </ui-button>
            </div>
        </ui-alert>
        <ui-alert v-if="showBanner" state="warning" class="td-alert">{{
            $t('subtitles.stripePendingActionsAllPages')
            }}</ui-alert>
        <router-view />
    </top-bar>
</template>

<script>
// @ is an alias to /src
import TopBar from "@/components/common/TopBar.vue";
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';

export default {
    name: 'SidebarContentLayout',
    components: {
        TopBar,
    },
    mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/enterprise.js?render=${process.env.VUE_APP_RECAPTCHA_KEY}`)
        document.head.appendChild(recaptchaScript)
    },
    computed: {
        ...mapState(useMainStore, ["userLogged"]),
        showBanner() {
            return this.userLogged && !this.userLogged.onboardingCompleto
        },
        stripeLink() {
            if (this.userLogged) {
                return process.env.VUE_APP_STRIPE_DASHBOARD.replace('__STRIPE_ID__', this.userLogged.connectId)
            }
            return "#"
        },
        showStripeLink() {
            return this.userLogged && this.userLogged.sessionType === 'OWNER'
        }
    },
    methods: {
        goToStripe() {
            window.open(this.stripeLink, 'blank');
        }
    }
}

</script>

<style>
aside.mdc-drawer {
    width: 320px;
}

.td-alert {
    margin: 10px 40px 10px 40px;
}
</style>