<template>
  <h2>{{ $t('subtitles.profile') }}</h2>
  <div id="user-form-container">
    <ui-card id="card-container" v-shadow.transition="[2, 8]">
      <ui-form type="|" item-margin-bottom="10" action-align="right">
        <template #default="{ subitemClass, actionClass }">
          <ui-form-field :class="subitemClass">
            <ui-textfield input-type="email" outlined fullwidth v-model="formData.email"
              helper-text-id="email-helper-text" disabled>
              {{ $t('filters.email') }}
              <template #before>
                <ui-textfield-icon>email</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="email-helper-text" v-model:validMsg="validMsg.email"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield outlined fullwidth v-model="formData.nombres" helper-text-id="nombres-helper-text"
              :disabled="formLoading">
              {{ $t('user.names') }}
              <template #before>
                <ui-textfield-icon>person</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="nombres-helper-text" v-model:validMsg="validMsg.nombres"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield outlined fullwidth v-model="formData.apellidos" helper-text-id="lastname-helper-text"
              :disabled="formLoading">
              {{ $t('user.lastname') }}
              <template #before>
                <ui-textfield-icon>person</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="lastname-helper-text" v-model:validMsg="validMsg.apellidos">
            </ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-divider></ui-divider>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <label> {{ $t('user.passwordUpdate') }}</label>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-switch v-model="formData.cambiarPwd"></ui-switch>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-helper-text"
              v-model="formData.passwordActual" v-show="formData.cambiarPwd" :disabled="formLoading"
              :attrs="{ autocomplete: 'current-password' }">
              {{ $t('user.currentPassword') }}
              <template #before>
                <ui-textfield-icon>vpn_key</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="password-helper-text" v-model:validMsg="validMsg.passwordActual"
              v-show="formData.cambiarPwd"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-nuevo-helper-text"
              v-model="formData.passwordNuevo" v-show="formData.cambiarPwd" :disabled="formLoading">
              {{ $t('user.newPassword') }}
              <template #before>
                <ui-textfield-icon>vpn_key</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="password-nuevo-helper-text" v-model:validMsg="validMsg.passwordNuevo"
              v-show="formData.cambiarPwd"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="subitemClass">
            <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-confirmar-helper-text"
              v-model="formData.passwordConfirmar" v-show="formData.cambiarPwd" :disabled="formLoading">
              {{ $t('user.confirmNewPassword') }}
              <template #before>
                <ui-textfield-icon>vpn_key</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="password-confirmar-helper-text" v-model:validMsg="validMsg.passwordConfirmar"
              v-show="formData.cambiarPwd"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="actionClass">
            <ui-button raised native-type="submit" @click.prevent="updateUserFromStore"
              @keyup.enter="updateUserFromStore" :disabled="formLoading"> {{ $t('actions.update') }}
              <template #after="{ iconClass }">
                <ui-icon :class="iconClass">save_alt</ui-icon>
              </template>
            </ui-button>
          </ui-form-field>
        </template>
      </ui-form>
      <ui-progress v-show="formLoading" indeterminate active></ui-progress>
      <error-component v-show="errorUpdate" :message="errorMessage" />
    </ui-card>
  </div>
  <h2>{{ $t('subtitles.stripeInfo') }}</h2>
  <error-component v-show="errorStripe" :message="errorStripeMessage" />
  <ui-alert v-if="userLogged && !userLogged.onboardingCompleto" state="warning"
    style="margin-left: 40px; margin-right: 40px;">{{
    $t('subtitles.stripePendingActions')
  }}</ui-alert>
  <div id="stripe-container"></div>
</template>
<script>
import { useMainStore } from '@/stores/main';
import { mapState, mapActions } from 'pinia';
import { useValidator, helpers } from "balm-ui";
import UserAPI from "@/api/UserAPI.js";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import i18n from '@/i18n';
import { loadConnectAndInitialize } from "@stripe/connect-js";

const validations = [
  {
    key: "email",
    label: i18n.global.t('filters.email'),
    validator: "required",
  },
  {
    key: "nombres",
    label: i18n.global.t('user.names'),
    validator: "required",
  },
  {
    key: "apellidos",
    label: i18n.global.t('user.lastname'),
    validator: "required",
  },
  {
    key: "passwordActual",
    label: i18n.global.t('user.currentPassword'),
    validator: "requiredIfEnabled",
    requiredIfEnabled: {
      validate(value, data) {
        if (data.cambiarPwd && helpers.isEmpty(value)) return false;
        return true;
      },
      message: "%s " + i18n.global.t('validations.required'),
    },
  },
  {
    key: "passwordNuevo",
    label: i18n.global.t('user.newPassword'),
    validator: "requiredIfEnabled, passConditions",
    requiredIfEnabled: {
      validate(value, data) {
        if (data.cambiarPwd && helpers.isEmpty(value)) return false;
        return true;
      },
      message: "%s " + i18n.global.t('validations.required'),
    },
    passConditions: {
      validate(value, data) {
        if (data.cambiarPwd) {
          var result = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})/.test(value);
          return result;
        }
        return true;
      },
      message:
        "%s " + i18n.global.t('validations.passwordRequirements'),
    },
  },
  {
    key: "passwordConfirmar",
    label: i18n.global.t('user.confirmNewPassword'),
    validator: "requiredIfEnabled, repasswordRule",
    requiredIfEnabled: {
      validate(value, data) {
        if (data.cambiarPwd && helpers.isEmpty(value)) return false;
        return true;
      },
      message: "%s " + i18n.global.t('validations.required'),
    },
    repasswordRule: {
      validate(value, data) {
        if (data.cambiarPwd && value !== data.passwordNuevo) return false;
        return true;
      },
      message: i18n.global.t('validations.passwordMismatch'),
    },
  },
];

export default {
  name: "UserData",
  components: {
    ErrorComponent,
  },
  data() {
    return {
      balmUI: useValidator(),
      validations,
      validMsg: {},
      formData: {
        email: "",
        nombres: "",
        apellidos: "",
        cambiarPwd: false,
        passwordActual: "",
        passwordNuevo: "",
        passwordConfirmar: "",
      },
      formLoading: false,
      errorUpdate: false,
      errorMessage: "",
      errorStripe: false,
      errorStripeMessage: ""
    };
  },
  async mounted() {
    await this.getUserData();
  },
  computed: {
    ...mapState(useMainStore, ["userLogged", "defaultLanguage"]),
  },
  methods: {
    ...mapActions(useMainStore, ['updateUser']),
    async getUserData() {
      this.formLoading = true;
      try {
        const payload = {
          token: this.userLogged.token,
          sessionType: this.userLogged.sessionType,
        };
        const userAPI = new UserAPI();
        const userData = await userAPI.getUserData(payload);
        this.formData.email = userData.correo;
        this.formData.nombres = userData.nombre;
        this.formData.apellidos = userData.apellidos;

        if (this.userLogged.sessionType === 'OWNER') {
          await this.loadStripeAccountData(userData.external_bankaccount_Id);
        }

        this.formLoading = false;
      } catch (error) {
        this.formLoading = false;
        this.errorMessage = error;
        this.errorUpdate = true;
      }
    },
    async loadStripeAccountData(connectedAccountId) {

      const stripeConnectInstance = loadConnectAndInitialize({
        // This is your test publishable API key.
        publishableKey: process.env.VUE_APP_STRIPE_KEY_MX,
        locale: this.defaultLanguage,
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css?family=Nunito'
        }],
        appearance: {
          variables: {
            colorPrimary: '#652d66',
            colorText: '#000',
            colorDanger: '#df1b41',
            fontFamily: 'Nunito',
          }
        },
        fetchClientSecret: async () => {
          try {

            const payload = {
              token: this.userLogged.token,
              connectAccountId: connectedAccountId
            };
            const userAPI = new UserAPI();
            const stripeData = await userAPI.getStripeData(payload);
            //console.log(stripeData)
            return stripeData.clientSecret
          } catch (error) {
            this.errorStripeMessage = error;
            this.errorStripe = true;
            return undefined
          }
        },
      });

      // Include this element in your HTML
      const accountOnboarding = stripeConnectInstance.create('account-onboarding');
      accountOnboarding.setCollectionOptions({
        fields: 'eventually_due',
        futureRequirements: 'include',
      })
      accountOnboarding.setOnExit(async () => {
        //console.log('User exited the onboarding flow');
        await this.getUserData();
      });
      const stripeContainer = window.document.getElementById("stripe-container");
      stripeContainer.appendChild(accountOnboarding);
    },
    async updateUserFromStore() {
      let result = this.balmUI.validate(this.formData);
      let { valid, validMsg } = result;
      this.validMsg = validMsg;

      if (valid) {
        this.formLoading = true;
        this.errorUpdate = false;

        try {
          const userAPI = new UserAPI();
          let success = true;

          if (this.formData.cambiarPwd) {
            success = await userAPI.validatePassword({
              sessionType: this.userLogged.sessionType,
              correo: this.userLogged.correo,
              password: this.formData.passwordActual,
              token: this.userLogged.token,
            });
          }

          if (success) {
            await this.updateUser({
              sessionType: this.userLogged.sessionType,
              correo: this.userLogged.correo,
              nombre: this.formData.nombres,
              password: this.formData.passwordNuevo,
              apellidos: this.formData.apellidos,
              token: this.userLogged.token,
            });
            this.formLoading = false;
            this.$toast(this.$t('user.updateConfirmation'));
          }
        } catch (error) {
          this.formLoading = false;
          this.errorMessage = error;
          this.errorUpdate = true;
          //console.log(error);
        }
      }
    },
  },
};
</script>
<style scoped>
h2,
h5 {
  text-align: center;
  margin-top: 2em;
}

#user-form-container {
  max-width: 480px;
  padding-bottom: 40px;
  margin: auto;
}

#card-container {
  padding: 1.2em;
  margin-top: 3em;
}
</style>